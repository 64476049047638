import { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { COLORS } from "src/layout/constants"
import { formatDate } from "src/helpers/date"
import RightIcon from "src/svg/right.svg"

const TableContainer = styled.table<{ $desc: boolean }>`
  margin-top: 30px;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  tr {
    th,
    td {
      border: 2px solid ${COLORS.sherpa};
      font-size: 0.8em;
      padding: 0;
    }
  }
  thead {
    th {
      cursor: pointer;
      padding: 12px 40px 12px 5px;
      background-color: ${COLORS.blue};
      background-clip: padding-box;
      white-space: nowrap;
      text-align: left;
      text-transform: uppercase;
      position: relative;
      svg {
        transform: rotate(${({ $desc }) => ($desc ? 90 : -90)}deg);
      }
    }
  }
  tbody {
    tr:hover {
      background-color: ${COLORS.lightPink};
    }
    td {
      height: 100%;
      a {
        display: block;
        height: 100%;
        padding: 5px;
        text-decoration: none;
      }
    }
  }
  @media (max-width: 799px) {
    .nomobile {
      display: none;
    }
    thead th {
      padding: 5px 5px 32px;
    }
  }
`
const SortIcon = styled(RightIcon)<{ $sorted: boolean }>`
  visibility: ${({ $sorted }) => ($sorted ? "visible" : "hidden")};
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 7px;
  right: 7px;
  circle {
    fill: ${COLORS.sherpa};
  }
  @media (max-width: 799px) {
    width: 20px;
    height: 20px;
  }
`

export interface Column<T extends SlugifiedNode> {
  id: keyof T
  title: string
  type?: "date" | "objects"
}

const formatCell = (value: any, type: Column<any>["type"]) => {
  if (value == null) {
    return ""
  }
  if (type === "date") {
    return formatDate(value, "MMM yyyy")
  }
  if (type === "objects") {
    value = value.map(({ title }) => title)
  }
  if (Array.isArray(value)) {
    return value.join(", ")
  }
  return value
}

export interface Sort<T> {
  id?: keyof T
  desc?: boolean
}

interface Props<T extends SlugifiedNode> {
  columns: Column<T>[]
  mobileHidden?: (keyof T)[]
  rows: T[]
  getPath: (item: T) => string
  defaultSort?: Sort<T>
}

function Table<T extends SlugifiedNode>({ columns, mobileHidden, rows, getPath, defaultSort }: Props<T>) {
  const [sort, setSort] = useState<Sort<T>>(defaultSort || {})

  if (sort.id) {
    rows.sort((left, right) => {
      const leftValue = String(left[sort.id]).toLowerCase()
      const rightValue = String(right[sort.id]).toLowerCase()
      if (leftValue === rightValue) {
        return 0
      }
      const sorter = sort.desc ? -1 : 1
      return leftValue > rightValue ? sorter : -sorter
    })
  }

  const handleSort = (id: keyof T) => () => {
    setSort({
      id,
      desc: sort.id === id ? !sort.desc : false,
    })
  }

  return (
    <TableContainer $desc={sort.desc}>
      <thead>
        <tr>
          {columns.map(({ id, title }) => (
            <th key={id as string} onClick={handleSort(id)} className={mobileHidden.includes(id) ? "nomobile" : ""}>
              {title}
              <SortIcon $sorted={id === sort.id} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            {columns.map(({ id, type }) => (
              <td key={id as string} className={mobileHidden.includes(id) ? "nomobile" : ""}>
                <Link to={getPath(row)}>{formatCell(row[id], type)}</Link>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableContainer>
  )
}

export default Table
