import { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "src/layout"
import { PageHead, Section } from "src/layout/styled"
import { COLORS, LAYOUT } from "src/layout/constants"
import Filter from "src/components/Filter"
import Table, { Sort, Column } from "src/components/Table"
import { strSearch } from "src/helpers/text"
import BookIcon from "src/svg/book.svg"

const COLUMNS: Column<Mission>[] = [
  { id: "title", title: "Titre" },
  { id: "subtitle", title: "Sujet" },
  { id: "client", title: "Commanditaire" },
  { id: "methods", title: "Méthodes" },
  { id: "start", title: "Début", type: "date" },
  { id: "end", title: "Fin", type: "date" },
  { id: "activities", title: "Activités", type: "objects" },
]
const DEFAULT_SORT: Sort<Mission> = {
  id: "end",
  desc: true,
}
const MOBILE_HIDDEN: (keyof Mission)[] = ["subtitle", "methods", "end"]

const getPath = (mission: Mission) => `/missions/${mission.fields.slug}/`

type Searchable = string | string[] | Record<string, string>

const SEARCH_FIELDS: Filtered<Mission, Searchable>[] = ["title", "subtitle", "client", "intro", "plainText"]

const find = (mission: Mission, text: string) => {
  return SEARCH_FIELDS.some((field) => {
    const value = mission[field]
    if (Array.isArray(value)) {
      return value.some((str) => strSearch(str, text))
    }
    if (typeof value === "string") {
      return strSearch(value, text)
    }
    return strSearch(value[field], text)
  })
}

const Missions = styled.div`
  font-size: 0.9em;
  margin: ${LAYOUT.margin * 2}px 0;
`
const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid ${COLORS.sherpa};
  width: 300px;
  margin: 10px;
  padding: 4px;
  outline: none;
  color: ${COLORS.sherpa};
  &:focus {
    background-color: ${COLORS.translucentWhite};
  }
  @media (max-width: 499px) {
    width: 100%;
    margin: 10px 0;
  }
`

interface Data {
  activities: { nodes: Activity[] }
  missions: { nodes: Mission[] }
}

const MissionsPage: GatsbyPage<Data> = ({ data }) => {
  const [searchFilter, setSearch] = useState("")
  const [activityFilter, setActivityFilter] = useState<string>()
  const [methodFilter, setMethodFilter] = useState<string>()

  const activities = data.activities.nodes.map(({ title }) => title)

  const methods = new Set<string>()
  data.missions.nodes.forEach((mission) => {
    if (mission.methods) {
      mission.methods.forEach((method) => {
        methods.add(method)
      })
    }
  })

  const missions = data.missions.nodes.filter((mission) => {
    if (mission.clientShort) {
      mission.client = mission.clientShort // hack
    }
    if (methodFilter && mission.methods && !mission.methods.includes(methodFilter)) {
      return false
    }
    if (activityFilter && (!mission.activities || !mission.activities.find(({ title }) => title === activityFilter))) {
      return false
    }
    const searchStr = searchFilter.trim().toLowerCase()
    if (searchStr && !find(mission, searchStr)) {
      return false
    }
    return true
  })

  const subtitle = "Un moteur de recherche pour accéder à toutes nos missions."

  return (
    <Layout theme="blue" title="Missions" description={subtitle}>
      <PageHead>
        <BookIcon />
        <h1>index des missions</h1>
        <h2>{subtitle}</h2>
      </PageHead>
      <Section>
        <Missions>
          <p>
            <strong>Recherche</strong>
            <SearchInput value={searchFilter} onChange={({ currentTarget }) => setSearch(currentTarget.value)} />
          </p>
          <Filter
            title="Filtres activités"
            values={activities}
            selected={activityFilter}
            onChange={setActivityFilter}
          />
          <Filter
            title="Filtres méthodologiques"
            values={Array.from(methods)}
            selected={methodFilter}
            onChange={setMethodFilter}
          />
          <Table<Mission>
            columns={COLUMNS}
            mobileHidden={MOBILE_HIDDEN}
            rows={missions}
            getPath={getPath}
            defaultSort={DEFAULT_SORT}
          />
        </Missions>
      </Section>
    </Layout>
  )
}

export default MissionsPage

export const query = graphql`
  query {
    activities: allContentfulActivity(sort: { fields: position }) {
      nodes {
        title
      }
    }
    missions: allContentfulMission {
      nodes {
        id: contentful_id
        title
        subtitle
        intro {
          intro
        }
        plainText
        methods
        client
        clientShort
        start
        end
        fields {
          slug
        }
        activities: activity {
          title
        }
      }
    }
  }
`
