export const normalize = (text: string) => {
  try {
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  } catch (_) {
    // Internet Explorer
    text = text.replace(/[éè]/gi, "e").replace(/à/gi, "a").replace(/ù/g, "u")
  }
  return text.toLowerCase()
}

export const strSearch = (source: string, query: string) => normalize(source).includes(normalize(query))
